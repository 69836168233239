.skills{
  background: rgb(25, 28, 29);
  height: 800px;
  display: flex;
  align-items:center;
  flex-direction:column;
}

.skills .title-box{
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.skills .title-box h2{
  background-color: #f7f9ff;
  font-size: 3rem;
  padding: 0 2rem;
  color: black ;
}

.wrapper{
  height: auto;
  display: flex;
  margin-left: 150px;
  overflow: hidden;
  margin: auto 0;
}

i{
  font-size: 5rem;
  color: #f7f9ff;
}

.skills-icon-list{
  display: flex;
  flex-direction: column;
}   

.wrapper i{
  display: block;
}

.line{
  background-color:#f7f9ff !important;
  height: 100%;
  width: 5px;
  display: block;
  margin-right: 20px;
}

.skills-list{
  font-size: 40px;
  line-height: 2;
  color: #f7f9ff;
  display: flex;
  flex-direction: column;
  white-space: nowrap ;
  overflow: hidden;
}
  
@media screen and (max-width: 433px){
    .skills{
        height: 600px;
    }
    
    .skills-icon-list i{
        font-size: 4rem;
    }
  
    .skills-list span{
        font-size: 32px;
    }

}