.section-hero{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: end    ;
  background: rgb(25, 28, 29);

}

.profile-box{
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.profile{
  width: 600px;
}

.text-box{
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-right: 15%;
  margin-bottom: 200px;
}

.text-box::before{
  content: '';
  display: block;
  position:absolute ;
  top: 0px;
  left: -15px;
  width: 8px;
  height: 150px;
  background-color: #fff;
}

.name{
  font-size: 4rem;
  color: #f7f9ff;
  font-weight: 500;
  padding: 0;
}

.role{
  font-size: 2rem;
  font-weight: 400;
  margin-left: 0.5rem;
  color: #f7f9ff;
}

.location{
  font-size: 1.3rem;
  color: #f7f9ff;
  margin-left: 0.5rem;
}

@media screen and (max-width: 1275px) {
    .text-box{
        margin-right: 0px;
    }
  
    .text-box::before{
        height: 120px;
    }
  
    .name{
        font-size: 2.5rem;
    }
  
    .role{
        font-size: 1.5rem;
    }
  
    .location{
        font-size: 1.3rem;
    }
  }
  
  @media screen and (max-width: 971px) {
    .section-hero{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
    }
    
    .profile-box{
        grid-row: 2;
    }
  
    .profile{
        width: 400px;
    }
  
    .text-box{
        grid-row: 1;
        margin: 0;
        justify-self: end;
    }
  }

@media screen and (max-width: 550px){
  .profile{
    width: 300px;
    
  }
  
  .text-box{
      width: 250px;
      grid-row: 1;
      margin: 0;
      margin-right: 50px;
     top: 50px;
  }

  .text-box::before{
      height: 100px;
  }

  .name{
      font-size: 2.2rem;
  }

  .role{
      font-size: 1.2rem;
  }

  .location{
      font-size: 1.2rem;
  }
}

@media screen and (max-width: 360px){
  .text-box{
      width: 250px;
      grid-row: 1;
      margin: 0;
      top:50px
  }

  .text-box::before{
      height: 80px;
  }

  .name{
      font-size: 2rem;
  }

  .role{
      font-size: 1rem;
  }

  .location{
      font-size: .8rem;
  }
}