.about{
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
}   

.about .title-box{
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.about .title-box h2{
  background-color: black;
  font-size: 3rem;
  padding: 0 2rem;
  color: #f7f9ff;
}

.about-wrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about-img-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img{
  width: 50%;
}

.about-text-box{
  font-size: 1.8rem;
  padding: 5rem 3rem;
  padding-right: 16rem;
  margin-left: -8rem;
  text-justify: auto;
  
}

.about-text-box p {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 1275px) {
  .about-img-box{
      justify-content: flex-start;
  }

  .about-img{
      width: 300px;
      margin-left: 50px;
  }

  .about-text-box{
      font-size: 1.2rem;
      padding-right: 5rem;
      margin-left: -4rem;
  }
}

@media screen and (max-width: 750px){
  .about-wrapper{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 400px 1fr;
  }

  .about-img-box{
      justify-content: center;
  }

  .about-img{
      width: 300px;
      margin-left: 0px;
  }

  .about-text-box{
      font-size: 1.2rem;
      padding: 0 3rem;
      margin-left: 0;
      justify-content: start;
  }
}