header{
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #191c1d;
  position:fixed;
  top: 0px;
  z-index: 2;
}

.side-nav{
  position: fixed;
  right: 0;
  display: flex;
  height: 100vh;
  width: 0px;
  background-color: #f7f9ff;
  z-index: 100;
  transition: width 500ms;
}

.side-nav ul{
  width: 100%;
}

.side-nav-element{
  width: 100%;
  list-style: none;
  font-size: 2rem;
  justify-content: center;
  padding: 1rem;
}

.side-nav-element:first-child{
  padding: 0rem;
}

.side-nav-element a{
  color: rgb(25, 28, 29);
}

.side-nav-element a:hover{
  color: rgb(124, 128, 129);
}

.shadow{
  box-shadow: 1px 1px 50px rgb(100, 100, 100);
}

header span{
  font-size: 1.5rem;
  letter-spacing: 0.175rem;
  margin-left: 3rem;
  color: #f7f9ff;
  cursor: pointer;
}

header ul{
  list-style: none;
  font-size: 1.5rem;
  letter-spacing: 0.175rem;
  cursor: pointer;
  display: flex;
  gap: 3rem;
  margin-right: 3rem;
  position: relative;
}

header ul li:last-child{
  display: none;
}

a{
  text-decoration: none;
  color: #f7f9ff;
}

a:hover{
  color: #c5c6c9;
}

@media screen and (max-width: 750px){
  header ul li{
      display: none;
  }
  
  header ul li:last-child{
      display: block;
  } 
}

@media screen and (max-width: 550px){
  header ul li i{
      font-size: 2.5rem;
  }
}

@media screen and (max-width: 360px){
  
  header span{
      margin-left: 1rem;
  }

  header ul{
      margin-right: 1rem;
  }

  header ul li i{
      font-size: 2rem;
  }
}