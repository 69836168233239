.projetos{
  background-color: #f7f9ff;
}

.projetos .title-box{
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.projetos .title-box h2{
  background-color: black;
  font-size: 3rem;
  padding: 0 2rem;
  color: #f7f9ff;
}

.container-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.projeto{
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.projeto-text-box{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projeto-text-box span{
  font-size: 1.5rem;
  font-weight: bold;
}

.projeto-text-box a{
  color: black;
}

.projeto-text-box a:hover{
  text-decoration: underline;
}

.projeto-img{
  width: 80%;
}

.projeto-img:hover{
  cursor: pointer;
}

.projeto-text-box span:hover{
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1275px) {
  .projeto-img{
      width: 90%;
  }

  .projeto-text-box span{
      font-size: 1rem;
  }
}

@media screen and (max-width: 750px){
  .container-grid{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr) ;
  }
  .projeto{
      height: 300px;
  }

  .projeto-img{
      width: 70%;
  }

  .projeto-text-box span{
      font-size: 1.5rem;
  }

  .projetos .title-box{
      padding-top: 0;
  }
}

@media screen and (max-width: 360px){
  .container-grid{
      grid-template-rows: repeat(3, 330px) ;
  }

  .title-box{
    margin-bottom: -50px;
  }
}