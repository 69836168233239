footer{
  height: 300px;
  background:  rgb(25, 28, 29);
}

footer .title-box{
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

footer .title-box h2{
  background-color:#f7f9ff ;
  font-size: 3rem;
  padding: 0 2rem;
  color: black;
}

.logo-box{
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  background-color: rgb(25, 28, 29);
}

@media screen and (max-width: 550px){
    footer .title-box h2{
        font-size: 1.6rem;
    }
  
    .logo-box{
        gap: 5rem;
    }
}